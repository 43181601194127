<template>
  <div>
    <el-form :inline="true" :model="form" ref="form" size="small">
      <el-form-item label="商品状态" prop="soldOut" label-width="100px">
        <el-select
            v-model="form.soldOut"
            placeholder="请选择商品状态"
            style="width: 240px"
            clearable
        >
          <el-option
              v-for="item in $dict.goodsAuditStatus"
              :key="item.code"
              :label="item.text"
              :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开售状态" prop="canBuy" label-width="100px">
        <el-select
            v-model="form.canBuy"
            placeholder="请选择开售状态"
            style="width: 240px"
            clearable
        >
          <el-option
              v-for="item in $dict.canBuyStatus"
              :key="item.code"
              :label="item.text"
              :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称" prop="goodsName" label-width="100px">
        <el-input
            style="width: 240px"
            clearable
            v-model="form.goodsName"
            placeholder="请输入商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间" label-width="100px">
        <el-date-picker
            style="width: 240px"
            value-format="yyyy-MM-dd"
            @change="pickerChange"
            v-model="picker_date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="商品类别" placeholder="请选择">
        <el-select v-model="form.goodsType">
          <el-option
              v-for="(item, index) in GoodsType"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
            style="margin-left: 100px"
            type="primary"
            icon="el-icon-search"
            @click="onQuery"
        >搜索</el-button
        >
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格数据部分 start -->

    <el-table
        :data="list"
        border
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="序号" width="60px">
        <template slot-scope="scope">{{
            (form.pageNum - 1) * form.pageSize + scope.$index + 1
          }}</template>
      </el-table-column>
      <el-table-column prop="productId" label="商品ID"> </el-table-column>
      <el-table-column prop="name" label="商品名称"> </el-table-column>
      <el-table-column label="商品图片">
        <template slot-scope="scope">
          <el-image
              style="width: 80px; height: 80px"
              :src="scope.row.image_url"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="商品分类"> </el-table-column>
      <!-- <el-table-column prop="isOneText" label="专区类别"> </el-table-column> -->
      <el-table-column prop="salesModeId" label="商品类别">
        <template slot-scope="scope">
          <span v-if="scope.row.salesModeId === 7">普通商品</span>
          <span v-else-if="scope.row.salesModeId === 4">共享商品</span>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="库存"> </el-table-column>
      <el-table-column prop="soldOutText" label="商品状态"> </el-table-column>
      <el-table-column prop="canBuy" label="开售状态">
        <template slot-scope="scope">
          <span v-if="scope.row.canBuy === 1" class="canScale">已开售</span>
          <span v-else-if="scope.row.canBuy === 0" class="disScale"
          >未开售</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"> </el-table-column>
      <el-table-column prop="updated_at" label="更新时间"> </el-table-column>
      <el-table-column prop="sales_volume" label="销量"> </el-table-column>
      <el-table-column prop="reason" label="上次拒绝理由"> </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <!-- 商品状态： 0下架,1上架审核通过, 2 待审核,3未通过 -->
          <!-- 配送方式 -->
          <template>
            <el-button
                type="text"
                v-if="scope.row.salesModeId === 4"
                @click="choseSend(scope.row)"
            >配送方式</el-button
            >
          </template>
          <template v-if="scope.row.sold_out == 0">
            <el-button
                type="text"
                @click="onPutOrOutShelf(scope.$index, scope.row, 2)"
                v-show="scope.row.salesModeId !== 4"
            >上架</el-button
            >
            <el-button
                type="text"
                @click="onEdit(scope.$index, scope.row)"
                v-show="scope.row.salesModeId !== 4"
            >编辑</el-button
            >
            <el-button
                type="text"
                @click="onDelGoods(scope.$index, scope.row)"
                v-show="scope.row.salesModeId !== 4"
            >删除</el-button
            >
            <el-button
                type="text"
                @click="onLook(scope.$index, scope.row)"
                v-show="scope.row.salesModeId !== 4"
            >查看评价</el-button
            >
          </template>
          <template v-else-if="scope.row.sold_out == 1">
            <el-button type="text" @click="toGoodsDetails(scope.row)"
            >查看</el-button
            >
            <el-button
                type="text"
                @click="onPutOrOutShelf(scope.$index, scope.row, 1)"
                v-show="scope.row.salesModeId !== 4"
            >下架</el-button
            >
            <el-button
                type="text"
                @click="onSetInvenBtn(scope.$index, scope.row)"
                v-show="scope.row.salesModeId !== 4"
            >设置库存</el-button
            >
            <el-button
                type="text"
                @click="onLook(scope.$index, scope.row)"
                v-show="scope.row.salesModeId !== 4"
            >查看评价</el-button
            >
          </template>
          <template v-else-if="scope.row.sold_out == 2">
            <div class="f-c-c">待审核</div>
          </template>
          <template v-else-if="scope.row.sold_out == 3">
            <el-button type="text" @click="onEdit(scope.$index, scope.row)"
            >重新编辑</el-button
            >
            <el-button type="text" @click="onDelGoods(scope.$index, scope.row)"
            >删除商品</el-button
            >
          </template>
          <!-- 共享商品-上下架 -->
          <template v-if="scope.row.salesModeId == 4">
            <div>
              <el-button
                  type="text"
                  v-if="scope.row.count == 0"
                  @click="upOrdownShareGoods(scope.row.productId, 0)"
              >下架</el-button
              >
              <el-button
                  type="text"
                  v-else-if="scope.row.sold_out !== 1"
                  @click="upOrdownShareGoods(scope.row.productId, 1)"
              >上架</el-button
              >
            </div>
          </template>
          <template v-if="scope.row.canBuy == 0">
            <div>
              <el-button type="text" @click="canSale(scope.row.productId, 1)"
              >开售</el-button
              >
            </div>
          </template>
          <template v-if="scope.row.canBuy == 1">
            <div>
              <el-button type="text" @click="canSale(scope.row.productId, 0)"
              >停售</el-button
              >
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!-- 表格数据部分 end -->

    <div class="pagination-box">
      <el-pagination
          @size-change="whenSizeChange"
          @current-change="whenCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="$store.state.pageSize"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="设置库存" :visible.sync="showInvenDialog">
      <el-table
          border
          :data="specInfos"
          v-if="specInfos.length"
          @cell-click="tabClick"
      >
        <el-table-column
            :prop="item"
            :label="
            item.toString() == 'count'
              ? '库存'
              : item.toString() == 'productName'
              ? '规格'
              : item.toString() == 'repositoryId'
              ? 'id'
              : item.toString()
          "
            v-for="(item, ind) in Object.keys(this.specInfos[0])"
            :key="ind"
        >
          <template slot-scope="scope">
            <input
                type="text"
                v-model="scope.row[item]"
                :disabled="item.toString() != 'count'"
            />
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="f-c-c">
        <el-button type="primary" @click="confrimDialog(specInfos)"
        >确认更改</el-button
        >
        <el-button @click="showInvenDialog = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 查看商品评价 -->
    <el-drawer
        class="comment"
        :visible.sync="showDrawer"
        direction="rtl"
        :size="800"
    >
      <div slot="title">查看商品评价</div>
      <div class="name">商品名称：{{ goodsName }}</div>
      <el-table :data="comments" border>
        <el-table-column prop="id" label="评论ID"> </el-table-column>
        <el-table-column prop="content" label="评论内容"> </el-table-column>
        <el-table-column label="评价图片">
          <template slot-scope="scope" v-if="scope.row.imgList.length > 0">
            <!-- <p>{{scope.row.images}}</p> -->
            <!-- <el-image
             v-for="(item,id) in scope.row.images"
              style="width: 80px; height: 80px"
              :src="item.image_url"
            ></el-image> -->

            <el-image
                style="width: 80px; height: 80px"
                :src="scope.row.imgList[0].image_url"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="评论星级"> </el-table-column>
        <el-table-column prop="nick_name" label="评价用户昵称">
        </el-table-column>
        <el-table-column label="操作" width="">
          <template slot-scope="scope">
            <el-button type="text" @click="onDel(scope.$index, scope.row)"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            @size-change="whenCommentSizeChange"
            @current-change="whenCommentCurrChange"
            :current-page="commentForm.pageNum"
            :page-sizes="$store.state.pageSize"
            :page-size="commentForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="commentTotal"
            background
            v-if="commentTotal > 0"
        >
        </el-pagination>
      </div>
    </el-drawer>
    <!-- 弹层 -->
    <el-dialog
        title="商品信息"
        :visible.sync="goodsDetalisVisible"
        :append-to-body="true"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="商品图片">
          <div style="display: flex">
            <div
                v-for="(item, index) in goodsData.imageList"
                :key="index"
                class="shoplistimg"
            >
              <el-image
                  fit="cover"
                  style="width: 80px; height: 80px; margin-right: 4px"
                  :src="item.imageUrl"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="名称">
          <span>{{ goodsData.name }}</span>
        </el-form-item>
        <el-form-item label="分类">
          <span
          >{{ goodsData.productTypeParentName }}/{{
              goodsData.productTypeName
            }}</span
          >
        </el-form-item>
        <el-form-item label="专区">
          <span v-if="goodsData.isOne === 1">一号专区商品</span>
          <span v-else-if="goodsData.isOne === 2">普通商品</span>
        </el-form-item>
        <el-form-item label="规格">
          <el-table
              :data="goodsData.repositoryList"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
              border
              style="width: 100%"
          >
            <el-table-column prop="productName" label="规格" width="auto">
            </el-table-column>
            <el-table-column label="价格" width="auto">
              <template slot-scope="scope">
                <span v-if="scope.row.price">￥{{ scope.row.price }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- 市场价 -->
            <el-table-column label="市场价" width="auto">
              <template slot-scope="scope">
                <span v-if="scope.row.personal_price"
                >￥{{ scope.row.personal_price }}</span
                >
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="库存"> </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="配送">
          <el-checkbox-group v-model="goodsData.deliveryType">
            <el-checkbox label="2" name="deliveryType" disabled
            >物流</el-checkbox
            >
            <el-checkbox label="1" name="deliveryType" disabled
            >自提</el-checkbox
            >
            <el-checkbox label="3" name="deliveryType" disabled
            >配送</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <!--开售状态  -->
        <el-form-item label="开售状态" prop="canBuy">
          <el-radio-group v-model="goodsData.canBuy">
            <el-radio label="1" disabled>正式开售</el-radio>
            <el-radio label="0" disabled>暂不开售</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="详情">
          <div class="goods-details" v-html="goodsData.detail"></div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div style="width: 200px">
      <el-button
          type="primary"
          v-show="allDown"
          @click="allUpperOrlowerShelf(2)"
      >一键上架</el-button
      >
      <el-button type="danger" v-show="allUP" @click="allUpperOrlowerShelf(1)"
      >一键下架</el-button
      >
    </div>
    <!-- 物流选择弹层 -->
    <el-dialog
        title="请选择配送方式"
        :visible.sync="wuliuDialog"
        width="20%"
        center
    >
      <div style="color:red;margin:0 auto">必须选择一种配送方式！</div>
      <el-checkbox-group v-model="wuliuList">
        <el-checkbox label="1">自提</el-checkbox>
        <el-checkbox label="2">物流</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wuliuDialog = false">取 消</el-button>
        <el-button type="primary" @click="shopSendMsg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wuliuDialog: false, //物流弹层
      wuliuList: [],
      wuliuFrom: {
        productId: null, //商品ID
        deliveryType: [], //配送方式
      },
      allDown: false,
      allUP: false,
      picker_date: null,
      showInvenDialog: false, //是否显示设置库存的对话框
      showDrawer: false,
      form: {
        goodsType: "all", //商品类别 ,shared,common
        pageNum: 1,
        pageSize: 10,
        userId: "",
        goodsName: "",
        createBeginTime: "",
        createEndTime: "",
        soldOut: "", //商品审核状态：0下架,1上架审核通过, 2 待审核,3未通过
        canBuy: "", //开售状态
      },
      GoodsType: [
        { value: "all", label: "全部商品" },
        { value: "shared", label: "共享商品" },
        { value: "common", label: "普通商品" },
      ],
      list: [],
      productIdList: [],
      inputValue: [],
      total: 0,
      specInfos: [], //某个商品的所有规格信息
      goodsName: "",
      commentForm: {
        pageNum: 1,
        pageSize: 10,
      },
      commentTotal: 0,
      comments: [], //评价数据
      ommentsId: "", //评价id
      tabClickIndex: null, // 点击的单元格
      tabClickLabel: "", // 当前点击的列名
      lock: "",
      goodsDetalisVisible: false,
      goodsData: [],
    };
  },
  created() {
    // this.getList();
  },
  activated() {
    this.getList();
  },
  deactivated() {
    //切走
    this.getList();
  },
  computed: {},
  methods: {
    toGoodsDetails(item) {
      let resData = {
        productId: item.productId,
      };
      this.$api.goods.getSpecInfoById(resData).then((res) => {
        res.data.deliveryType = res.data.deliveryType.split(",");
        // console.log(res.data.deliveryType);
        this.goodsData = res.data ? res.data : [];
        this.goodsDetalisVisible = true;
      });
    },

    /**
     * @description:创建时间清空
     * @Date: 2022-10-28 14:18:26
     * @author: cc
     */
    pickerChange(value) {
      if (!value) {
        [this.form.createBeginTime, this.form.createEndTime] = [null, null];
        return;
      }
      [this.form.createBeginTime, this.form.createEndTime] = value;
    },

    getList() {
      this.form.userId = this.$store.state.user.userId;

      this.$api.goods
          .manageList(this.$formatSubForm(this.form))
          .then((res) => {
            // console.log(res);
            let { data, message, success } = res;
            if (success) {
              let { list, total } = data;
              // console.log(list,'list')
              this.list = list.map((item) => {
                item.isOneText = this.$dict.zoneTypes.filter(
                    (it) => it.code === item.is_one
                )[0].text;
                item.soldOutText = this.$dict.goodsAuditStatus.filter(
                    (it) => it.code === item.sold_out
                )[0].text;
                item.isSalesText = this.$dict.isContainFreight.filter(
                    (it) => it.code === item.is_sales
                )[0].text;
                return item;
              });
              this.total = parseInt(total);
              // console.log(this.form.soldOut=='0','lock')
              // if(this.form.soldOut==1||thisform.soldOut)
              if (this.form.soldOut == 1) {
                //判断当前是否选则上下架
                this.lock = 1;
                this.allUP = true;
                this.allDown = false;
                return;
              } else if (this.form.soldOut == "0") {
                this.lock = 2;
                this.allDown = true;
                this.allUP = false;
                return;
              }
              this.allDown = false;
              this.allUP = false;
              // console.log(this.allUP,this.allDown)
              return (this.lock = "");
            } else {
              // console.log('erring')
              this.list.length = 0;
              this.list = [];
              this.$message.warning(message);
            }
          })
          .catch((err) => {
            this.list.length = 0;
            // this.$message.warning(message);
          });
    },

    getCommentsList(id) {
      this.$api.goods.queryGoodsComments(id).then((res) => {
        // console.log(res, "res");
        this.comments = res.data.list;
        this.commentTotal = Number(res.data.total);

        this.showDrawer = true;
      });
    },

    deleteProductComments(id) {
      this.$api.goods.deleteProductComments(id).then((res) => {
        // console.log(res, "res");
        // this.comments=res.data
        // this.showDrawer=true
      });
    },

    deleteProduct(id) {
      this.$api.goods.deleteProduct(id).then((res) => {
        // console.log(res, "res");
        let { message, success } = res;
        this.$message[success ? "success" : "error"](message);
        // this.comments=res.data
        // this.showDrawer=true
      });
    },

    updateSpecInfoList(list) {
      this.$api.goods.updateSpecInfoList(list).then((res) => {
        if (res.success) {
          this.showInvenDialog = false;
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
        // console.log(res, "修改库存");
      });
    },

    confrimDialog(e) {
      // console.log(e, "inputValue");

      this.updateSpecInfoList({ repositoryInfo: JSON.stringify(e) });
    },

    onQuery() {
      this.form.pageNum = 1;
      this.getList();
    },

    onReset() {
      this.$refs.form.resetFields();
      this.form.createBeginTime = null;
      this.form.createEndTime = null;
      this.picker_date = null;
      this.form.pageSize = 10;
      this.form.goodsType = "all";
      this.onQuery();
      this.getList();
    },

    whenSizeChange(newPageSize) {
      let { pageNum, pageSize } = this.form;
      this.form.pageNum = Math.floor((pageNum * pageSize) / newPageSize);
      this.form.pageSize = newPageSize;
      this.getList();
    },

    whenCurrentChange(currPageNum) {
      this.form.pageNum = currPageNum;
      this.getList();
    },

    whenCommentSizeChange(newPageSize) {
      let { pageNum, pageSize } = this.commentForm;
      this.commentForm.pageNum = Math.floor((pageNum * pageSize) / newPageSize);
      this.commentForm.pageSize = newPageSize;
      this.getCommentsList({
        goodsId: this.ommentsId,
        page: this.commentForm.pageNum,
        size: this.commentForm.pageSize,
      });
    },

    whenCommentCurrChange(currPageNum) {
      console.log(currPageNum, "currPageNum");
      this.commentForm.pageNum = currPageNum;
      this.getCommentsList({
        goodsId: this.ommentsId,
        page: this.commentForm.pageNum,
        size: this.commentForm.pageSize,
      });
    },

    //上架商品
    //type: 1下架 2上架
    onPutOrOutShelf(ind, row, type) {
      this.$api.goods
          .putOrOutShelf({
            productId: row.productId,
            shelf: type,
          })
          .then((res) => {
            // console.log(res);
            let { message, success } = res;
            this.$message[success ? "success" : "error"](message);
            if (success) {
              let soldOut = "";
              switch (type) {
                case 1:
                  soldOut = 0;
                  break;
                case 2:
                  soldOut = 2;
                  break;
                default:
                  break;
              }
              this.list[ind].sold_out = soldOut;
              this.list[ind].soldOutText = this.$dict.goodsAuditStatus.filter(
                  (item) => item.code == soldOut
              )[0].text;
            }
          });
    },

    /* 共享商品-上下架 */
    upOrdownShareGoods(productId, type) {
      this.$api.goods
          .shelvesGoods({
            productId,
            shelf: type,
            shopType: "3",
          })
          .then((res) => {
            this.$message.success(res.message);
            this.getList();
          })
          .catch((err) => {
            console.log("失败");
          });
    },

    //点击设置库存按钮
    onSetInvenBtn(ind, row) {
      let that = this;
      that.showInvenDialog = true;
      that.$api.goods
          .getSpecInfoById({
            productId: row.productId,
          })
          .then((res) => {
            // console.log("需要更改的库存信息", res);
            let { goodsSpecFormat, repositoryList } = res.data;

            that.specInfos = repositoryList.map((item) => {
              // console.log('414',item)
              delete item.price;
              item["count"] = item.count;
              return {
                repositoryId: item.repositoryId,
                productName: item.productName,
                count: item.count,
              };
            });
            // console.log(that.specInfos, "specInfos");
          });
    },

    //编辑商品
    onEdit(ind, item) {
      this.$router.push({
        path: "/manage/goods/publish",
        query: { productId: item.productId },
      });
      //  this.$router.push({name:'testDemo',params:{setid:111222}});
      // console.log('编辑信息',ind,item.productId)
    },

    onDelGoods(a, b) {
      //删除商品
      // console.log('onDelGoods',a,b)
      this.deleteProduct({
        id: b.productId,
      });
      this.list = this.list.filter((item) => {
        // console.log(b.id)
        return item.productId != b.productId;
      });
    },

    //删除商品评价
    onDel(a, b) {
      // console.log(this.comments);
      this.deleteProductComments({
        commentsId: b.id,
      });
      this.comments = this.comments.filter((item) => {
        // console.log(b.id)
        return item.id != b.id;
      });
      // console.log(this.comments);
    },

    //查看评价
    onLook(a, b) {
      this.ommentsId = b.productId;
      this.goodsName = b.name;
      this.getCommentsList({
        goodsId: this.ommentsId,
        page: this.commentForm.pageNum,
        size: this.commentForm.pageSize,
      });
    },

    tabClick(row, column, cell, event) {
      switch (column.label) {
        case "原因说明":
          this.tabClickIndex = row.index;
          this.tabClickLabel = column.label;
          break;
        case "判责金额(元)":
          this.tabClickIndex = row.index;
          this.tabClickLabel = column.label;
          break;
        case "备注":
          this.tabClickIndex = row.index;
          this.tabClickLabel = column.label;
          break;
        default:
          return;
      }
      // console.log("添加明细原因", this.tabClickIndex, row, column, cell, event);
    },

    inputBlur(row, event, column) {
      this.tabClickIndex = null;
      this.tabClickLabel = "";
    },

    handleSelectionChange(e) {
      // console.log(e,'tables')
      this.productIdList = [];
      let arrTemp = [];
      e.forEach((element) => {
        arrTemp.push(element.productId);
      });
      this.productIdList = arrTemp;
      // console.log(this.productIdList,this.form.soldOut)
    },

    allUpperOrlowerShelf(type) {
      this.productIdList = this.productIdList.toString();
      // console.log(type,this.lock)
      if (type == 1 && this.lock == 1) {
        //下架
        this.$api.goods
            .allUpperOrlowerShelf({
              list: this.productIdList,
              shelf: type,
            })
            .then((res) => {
              // console.log(res)
              this.onQuery();
            });
        // console.log(type,this.productIdList)
      } else if (type == 2 && this.lock == 2) {
        //上架
        // console.log(type,this.productIdList)
        this.$api.goods
            .allUpperOrlowerShelf({
              list: this.productIdList,
              shelf: type,
            })
            .then((res) => {
              // console.log(res)
              this.onQuery();
            });
      }
    },

    // 配送方式
    async choseSend(row) {
      this.wuliuFrom.productId = row.productId;
      const params = {
        productId: row.productId,
      };
      this.$api.goods.getSpecInfoById(params).then((res) => {
        res.data.deliveryType = res.data.deliveryType.split(",");
        this.wuliuList = res.data.deliveryType ? res.data.deliveryType : [];

        this.wuliuDialog = true;
      });
    },

    shopSendMsg() {
      console.log(this.wuliuList);
      if (this.wuliuList[0] === "" && this.wuliuList.length <= 1) {
        this.$message({
          showClose: true,
          message: "必须选择一种配送方式！",
          type: "error",
        });
        return;
      }
      const res = this.wuliuList.toString();
      this.wuliuFrom.deliveryType = res;
      const resData = this.wuliuFrom;
      this.$api.goods.shopSendmsg(resData).then((res) => {
        this.wuliuDialog = false;
        this.wuliuList = [];
      });
    },

    // 设置开售状态
    canSale(productId, type) {
      this.$api.goods
          .updateProductcanBuy({
            productId: productId,
            canBuy: type,
          })
          .then((res) => {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getList();
          })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: "Warning",
            });
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  .name {
    font-weight: 600;
    font-size: 20px;
    margin-left: 24px;
    margin-bottom: 24px;
  }
  .el-table {
    width: calc(100% - 48px);
    margin: 0 auto;
  }
}
.goods-details {
  ::v-deep img {
    width: 100%;
  }
}
.canScale {
  color: #009353;
}
.disScale {
  color: #f40;
}
</style>
